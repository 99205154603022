import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import Cards from '../components/Cards'

function CreditScreen() {
  return (
    <div className='credit'>
        <Navbar/>
      <div className='credit_Content'>
        <Banner/>
        <Cards/>
        <Footer/>
      </div>
    </div>
  )
}

export default CreditScreen
