import React from 'react'
import "../components/Banner.css"

function ConsortiumBanner() {
  return (
    <div className='banner'>
        <div className='banner_Content'>
            <h1>ADQUIRA BENS E SERVIÇOS COM CARTAS DE CONSÓRCIO
            </h1>
            <button>Simule agora</button>
        </div>
    </div>
  )
}

export default ConsortiumBanner
