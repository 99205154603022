import React from 'react'
import "../components/Data.css"

function Data() {
  return (
    <div className='data'>
        <div className='data_Content'>
            <div className='data_Card'>
                <h1>+3 BI</h1>
                <p>crédito liberado</p>
            </div>
            <div className='data_Card'>
                <h1>+80</h1>
                <p>Assessores</p>
            </div>
            <div className='data_Card'>
                <h1>+10 MIL</h1>
                <p>Operações</p>
            </div>
        </div>
    </div>
  )
}

export default Data
