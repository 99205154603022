import React from 'react'
import Navbar from '../components/Navbar'
import AntecipationBanner from '../components/AntecipationBanner'
import AntecipationCards from '../components/AntecipationCards'
import Footer from '../components/Footer'

function AntecipationScreen() {
  return (
    <div className='antecipation'>
        <Navbar/>
        <AntecipationBanner/>
        <AntecipationCards/>
        <Footer/>
      
    </div>
  )
}

export default AntecipationScreen
