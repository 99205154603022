import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom'; 
import './Blog.css';
import { truncateContent } from '../functions/truncateContent';
import { formatDate } from '../functions/formatDate';

function Blog() {
  const [posts, setPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState(3);

  useEffect(() => {
    const fetchPosts = async () => {
      const querySnapshot = await getDocs(collection(db, 'blogPosts'));
      const fetchedPosts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPosts(fetchedPosts);
    };

    fetchPosts();
  }, []);

  const handleLoadMore = () => {
    setVisiblePosts(posts.length);
  };

  return (
    <div className="blog">
      <h2>BLOG</h2>
      <div className="blog_content">
        {posts.slice(0, visiblePosts).map((post) => (
          <div key={post.id} className="blog_post">
            <div className="blog-preview">
              <img src={post.cover} alt="Cover" />
            </div>
            <h3 className="blog_post_title">
              <Link to={`/blog/${post.id}`}>{post.title}</Link>
            </h3>
            <p className="blog_post_date">{formatDate(post.date)}</p>
            <p className="blog_post_content">
              {truncateContent(post.content)}
            </p>
          </div>
        ))}
      </div>
      {visiblePosts < posts.length && (
        <button className='blog_Button' onClick={handleLoadMore}>Load More</button>
      )}
    </div>
  );
}

export default Blog;
