import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HomeScreen from './screens/HomeScreen';
import BlogScreen from './screens/BlogScreen';
import BlogPage from './pages/BlogPage';
import './App.css';
import CreditScreen from './screens/CreditScreen';
import ConsortiumScreen from './screens/ConsortiumScreen';
import AntecipationScreen from './screens/AntecipationScreen';
import AboutScreen from './screens/AboutScreen';
import ContactScreen from './screens/ContactScreen';

// Component to track page views
const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'AW-16734400672', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return null;
};

function App() {
  return (
    <Router>
      <div className="App">
        <Helmet>
          {/* Google Tag Manager */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16734400672"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-16734400672');
            `}
          </script>
        </Helmet>
        
        <TrackPageView />

        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/linhas-de-credito" element={<CreditScreen />} />
          <Route path="/consorcio" element={<ConsortiumScreen />} />
          <Route path="/contato" element={<AboutScreen />} />
          <Route path="/sobre-nos" element={<ContactScreen />} />
          <Route path="/antecipacao-de-credito" element={<AntecipationScreen />} />
          <Route path="/blog" element={<BlogScreen />} />
          <Route path="/blog/:postId" element={<BlogPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
