import React from 'react'
import "../components/ContactBanner.css"

function ContactBanner() {

    const handleClick = () => {
        window.open('https://wa.me/5519995684118', '_blank');
      };

  return (
    <div className='contact'>
        <div className='contact_Content'>
            <div className='contact_Banner'>
                <h1>Nosso propósito é oferecer, com agilidade e inteligência, acesso às melhores soluções do mercado financeiro para você.
                </h1>
            </div>
            <div className='contact_History'>
                <div className='contact_Icon'>
                    nossa história
                </div>
                <p>Com mais de 6 anos de mercado, Finance X Capital atua com os mais diversos grupos financeiros do mundo. 
                    Não existem barreiras para nossa atuação, possuímos a mais completa equipe para realização de negócios em diversos lugares do mundo, ou seja, seus negócios sem fronteiras. 
                </p>
                <button className='contact_Button' onClick={handleClick}>Fale com nossos especialistas</button>
            </div>
        </div>
      
    </div>
  )
}

export default ContactBanner
