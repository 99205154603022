import React from 'react'
import "../components/AboutCards.css"

function AboutCards() {
  return (
    <div className='about_Cards'>
      <div className='about_Card'>
        <div className='about_Icon'>
          <img src="/images/icons/e-mail.svg" alt="icon" />
        </div>
        <h3>Envie um email em:</h3>
        <p>diretoria@financexcapital.com</p>
      </div>
      <div className='about_Card'>
        <div className='about_Icon'>
        <img src="/images/icons/cronometro.svg" alt="icon" />
        </div>
        <h3>Horário de atendimento:</h3>
        <p>Seg à Sex das 9h às 17</p>
      </div>
      <div className='about_Card'>
        <div className='about_Icon'>
        <img src="/images/icons/telefone.svg" alt="icon" />
        </div>
        <h3>Entre em contato:</h3>
        <p>+55 48 98873‑3067</p>
      </div>
      <div className='about_Card'>
        <div className='about_Icon'>
        <img src="/images/icons/localizacao.svg" alt="icon" />
        </div>
        <h3>Nosso Escritório:</h3>
        <p>Av. Juscelino Kubitschek, 2041 - Torre B, 5º andar - Vila Olímpia</p>
      </div>
    </div>
  )
}

export default AboutCards
