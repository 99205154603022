// src/firebase-config.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';  // Import Analytics

const firebaseConfig = {
  apiKey: "AIzaSyAxTpZELDatdG2yeuoqRw7jKvgtCvzLtRo",
  authDomain: "finance-x-f89b4.firebaseapp.com",
  projectId: "finance-x-f89b4",
  storageBucket: "finance-x-f89b4.appspot.com",
  messagingSenderId: "604234513197",
  appId: "1:604234513197:web:b970b52181dd6d9c9e31a4",
  measurementId: "G-W60W1TDY01"  // Measurement ID for Google Analytics
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Google Analytics
const analytics = getAnalytics(app);  // Initialize Analytics

export { db, analytics };
