import React from 'react'
import "../components/Hero.css"
import Services from './Services'

function Hero() {
  return (
    <div className='hero'>
        <Services/>
    </div>
  )
}

export default Hero