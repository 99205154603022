import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import './BlogPage.css';
import { formatDate } from '../functions/formatDate'; 
import Navigation from './Navigation';

function BlogPage() {
  const { postId } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const docRef = doc(db, 'blogPosts', postId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const postData = docSnap.data();
          setPost({
            id: docSnap.id,
            ...postData,
          });
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document: ', error);
      }
    };

    fetchPost();
  }, [postId]);

  if (!post) {
    return <div className='blog_Loading'>Carregando...</div>;
  }

  return (
    <div className="blog_Page">
      <Navigation/>
        <div className='blog_Page_Content'>
        <div className="blog-page-cover">
        <img src={post.cover} alt="Cover" />
      </div>
      <h1 className="blog-page-header">{post.title}</h1>
      <p className="blog-page-date">Published on {formatDate(post.date)}</p>
      <p className="blog-page-introduction">{post.introduction}</p>
      <div className="blog-post-content">
            {post.h1 && <div className="blog-post-section">
              <h3>{post.h1}</h3>
              <p>{post.p1}</p>
            </div>}
            {post.h2 && <div className="blog-post-section">
              <h3>{post.h2}</h3>
              <p>{post.p2}</p>
            </div>}
            {post.h3 && <div className="blog-post-section">
              <h3>{post.h3}</h3>
              <p>{post.p3}</p>
            </div>}
            {post.h4 && <div className="blog-post-section">
              <h3>{post.h4}</h3>
              <p>{post.p4}</p>
            </div>}
            {post.h5 && <div className="blog-post-section">
              <h3>{post.h5}</h3>
              <p>{post.p5}</p>
            </div>}
            {post.h6 && <div className="blog-post-section">
              <h3>{post.h6}</h3>
              <p>{post.p6}</p>
            </div>}
          </div>
      </div>
    </div>
  );
}

export default BlogPage;
