import React from 'react'
import "../components/Banner.css"

function AntecipationBanner() {
  return (
    <div className='banner'>
        <div className='banner_Content'>
            <h1>GARANTA LIQUIDEZ IMEDIATA COM A ANTECIPAÇÃO DE RECEBÍVEIS.
            </h1>
            <button>Simule agora</button>
        </div>
    </div>
  )
}

export default AntecipationBanner
