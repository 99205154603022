import React from 'react';
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import Data from '../components/Data';
import Map from '../components/Map';
// import Market from './components/Market';
import Footer from '../components/Footer';
import Blog from '../components/Blog';

function HomeScreen() {
  return (
    <div className="homeScreen">
      <Navbar />
      <Hero />
      <Data />
      <Map />
      <Blog/>
      {/* <Market /> */}
      <Footer />
    </div>
  );
}

export default HomeScreen;
