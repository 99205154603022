import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ContactBanner from '../components/ContactBanner'

function ContactScreen() {
  return (
    <div className='about'>
        <div className='about_Content'>
            <Navbar/>
             <ContactBanner/>
            <Footer/>
        </div>
      
    </div>
  )
}

export default ContactScreen
