import React from 'react'
import "../components/Cards.css"

function AntecipationCards() {

  const handleClick = () => {
    window.open('http://wa.me/5548988733067', '_blank');
  };
  
  return (
        <div className='cards'>
        <div className='cards_Content'>
            <h1>SOLUÇÕES DE CONSÓRCIO PERSONALIZADAS</h1>
            <p>Oferecemos opções de consórcio flexíveis e vantajosas para atender às suas necessidades específicas.</p>
            <div className='cards_Group'>
                <div className='card-1'>
                  <h2>Envio de Recebíveis</h2>
                  <div className='card_Text'>
                  <p>Sua empresa envia duplicatas, cheques ou boletos a serem antecipados para a Finance X Capital.</p>
                  </div>
                </div>
                <div className='card-2'>
                  <h2>Análise e Aprovação</h2>
                  <div className='card_Text'>
                  <p>Nossos especialistas analisam os recebíveis e aprovam a antecipação rapidamente, com base na solidez dos títulos.</p>
                  </div>
                </div>
            </div>
            <div className='cards_Group_2'>
            <div className='card-3'>
                  <h2>Recebimento Antecipado</h2>
                  <div className='card_Text'>
                  <p>Após a aprovação, o valor antecipado é creditado na conta da sua empresa, proporcionando liquidez imediata.</p>
                  </div>
                </div>
            </div>
            <h1 className='card_Title'>VANTAGENS E BENEFÍCIOS AO SEU ALCANCE</h1>
              <div className='card_Container'>
                <div className='container'>
                  <div className='container_Image'>
                  <img src="/images/icons/icon-5.svg" alt="icon" />
                  </div>
                  <h3>Liquidez imediata</h3>
                  <p> Acesso rápido ao capital necessário para suas operações diárias.</p>
                </div>
                <div className='container'>
                  <div className='container_Image'>
                  <img src="/images/icons/icon-1.svg" alt="icon" />
                  </div>
                  <h3>Melhoria do Fluxo de Caixa</h3>
                  <p>Maior previsibilidade e planejamento financeiro, garantindo a saúde financeira da empresa.</p>
                </div>
                <div className='container'>
                  <div className='container_Image'>
                  <img src="/images/icons/icon-2.svg" alt="icon" />
                  </div>
                  <h3>Parcerias Exclusivas</h3>
                  <p>Aproveite as condições especiais e taxas competitivas graças às nossas parcerias exclusivas.</p>
                </div>
              </div>
              <div className='card_Container'>
                <div className='container'>
                  <div className='container_Image'>
                  <img src="/images/icons/icon-3.svg" alt="icon" />
                  </div>
                  <h3>Suporte ao Crescimento</h3>
                  <p>Recursos direcionados para investimentos estratégicos em sua empresa.</p>
                </div>
                <div className='container'>
                  <div className='container_Image'>
                  <img src="/images/icons/icon-4.svg" alt="icon" />
                  </div>
                  <h3>Segurança</h3>
                  <p>Crédito respaldado por garantias sólidas, proporcionando maior tranquilidade.</p>
                </div>
              </div>
              <button onClick={handleClick}>Fale com nossos especialistas</button>
        </div>
    </div>
  )
}

export default AntecipationCards
