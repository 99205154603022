import React from 'react'
import "../components/Cards.css"

function ConsortiumCards() {

  const handleClick = () => {
    window.open('http://wa.me/5548988733067', '_blank');
  };
  
  return (
    <div className='cards'>
      <div className='cards_Content'>
        <h1>Soluções financeiras personalizadas</h1>
        <p>A antecipação de recebíveis permite que você tenha acesso imediato ao valor de suas vendas a prazo.</p>
        <div className='cards_Group'>
          <div className='card-1'>
            <h2>Envio de Recebíveis</h2>
            <div className='card_Text'>
              <p>Sua empresa envia duplicatas, cheques ou boletos a serem antecipados para a Finance X Capital.</p>
            </div>
          </div>
          <div className='card-2'>
            <h2>Análise e Aprovação</h2>
            <div className='card_Text'>
              <p>Nossos especialistas analisam os recebíveis e aprovam a antecipação rapidamente, com base na solidez dos títulos.</p>
            </div>
          </div>
          <div className='card-3'>
            <h2>Recebimento Antecipado</h2>
            <div className='card_Text'>
              <p>Após a aprovação, o valor antecipado é creditado na conta da sua empresa, proporcionando liquidez imediata.</p>
            </div>
          </div>
        </div>
      </div>
      <div className='cards_Content'>
        <h1>VANTAGENS E BENEFÍCIOS AO SEU ALCANCE</h1>
        <div className='card_Container'>
          <div className='container'>
            <div className='container_Image'>
              <img src="/images/icons/icon-5.svg" alt="icon" />
            </div>
            <h3>Liquidez Imediata</h3>
            <p>Acesso rápido ao capital necessário para suas operações diárias.</p>
          </div>
          <div className='container'>
            <div className='container_Image'>
              <img src="/images/icons/icon-1.svg" alt="icon" />
            </div>
            <h3>Melhoria do Fluxo de Caixa</h3>
            <p>Maior previsibilidade e planejamento financeiro, garantindo a saúde financeira da empresa.</p>
          </div>
          <div className='container'>
            <div className='container_Image'>
              <img src="/images/icons/icon-2.svg" alt="icon" />
            </div>
            <h3>Sem Endividamento Adicional</h3>
            <p>Receba antecipadamente sem aumentar a dívida da empresa, utilizando os recebíveis já esperados.</p>
          </div>
          </div>
          <div className='card_Container'>
          <div className='container'>
            <div className='container_Image'>
              <img src="/images/icons/icon-3.svg" alt="icon" />
            </div>
            <h3>Processo Ágil e Simplificado</h3>
            <p>Nossa equipe de especialistas garante um processo rápido e eficiente, com análise ágil e desembolso imediato.</p>
          </div>
          <div className='container'>
            <div className='container_Image'>
              <img src="/images/icons/icon-4.svg" alt="icon" />
            </div>
            <h3>Parcerias Exclusivas</h3>
            <p>Aproveite as condições especiais e taxas competitivas graças às nossas parcerias exclusivas.</p>
          </div>
        </div>
        <button onClick={handleClick}>Fale com nossos especialistas</button>
      </div>
    </div>
  )
}

export default ConsortiumCards
