import React from 'react'
import "../components/Banner.css"

function Banner() {
  return (
    <div className='banner'>
        <div className='banner_Content'>
            <h1>LINHAS DE CRÉDITO FLEXÍVEIS E COMPETITIVAS PARA IMPULSIONAR SEUS NEGÓCIOS.</h1>
            <button>Simule agora</button>
        </div>
    </div>
  )
}

export default Banner
