import React from 'react'
import ConsortiumBanner from '../components/ConsortiumBanner'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ConsortiumCards from '../components/ConsortiumCards'

function ConsortiumScreen() {
  return (
    <div className='consortium'>
      <Navbar/>
      <ConsortiumBanner/>
      <ConsortiumCards/>
      <Footer/>
    </div>
  )
}

export default ConsortiumScreen
