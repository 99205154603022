import React from 'react'
import "../components/Map.css"

function Map() {
  return (
    <div className='map'>
        <div className='map_Content'>
            <h2>PAÍSES DE ATUAÇÃO</h2>
            <img src="images/map.png" alt="map"/>
        </div>
    </div>
  )
}

export default Map
