import React from 'react'
import AboutBanner from '../components/AboutBanner'
import Navbar from '../components/Navbar'
import AboutCards from '../components/AboutCards'
import Footer from '../components/Footer'

function AboutScreen() {
  return (
    <div className='about'>
        <div className='about_Content'>
            <Navbar/>
            <AboutBanner/>
            <AboutCards/>
            <Footer/>
        </div>
      
    </div>
  )
}

export default AboutScreen
