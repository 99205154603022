import React from 'react'
import "../components/Cards.css"

function Cards() {

  const handleClick = () => {
    window.open('http://wa.me/5548988733067', '_blank');
  };
  
  return (
    <div className='cards'>
        <div className='cards_Content'>
            <h1>SOLUÇÕES FINANCEIRAS PERSONALIZADAS</h1>
            <p>Oferecemos linhas de crédito flexíveis e competitivas para impulsionar seus negócios e atender às suas necessidades financeiras específicas.</p>
            <div className='cards_Group'>
                <div className='card-1'>
                  <a href="https://financexcapital.com.br/home-equity/" target="_blank" rel="noopener noreferrer">
                    <h2>Home Equity</h2>
                    <div className='card_Text'>
                      <p>Utilize seu imóvel como garantia para obter crédito com condições especiais. Taxas de juros reduzidas e até 240 meses para pagar.</p>
                      <p>*Sem consulta no SPC/SERASA.</p>
                    </div>
                  </a>
                </div>
                <div className='card-2'>
                  <a href="https://financexcapital.com.br/credito-aval/" target="_blank" rel="noopener noreferrer">
                    <h2>Créditos do Aval</h2>
                    <div className='card_Text'>
                      <p>Acesso a recursos financeiros com garantias sólidas, facilitando a obtenção de crédito rápido e confiável.</p>
                    </div>
                  </a>
                </div>
            </div>
            <div className='cards_Group_2'>
              <div className='card-3'>
                <a href="https://financexcapital.com.br/bndes/" target="_blank" rel="noopener noreferrer">
                  <h2>BNDES</h2>
                  <div className='card_Text'>
                    <p>Financiamento com recursos do Banco Nacional de Desenvolvimento Econômico e Social, ideal para projetos de expansão e modernização.</p>
                  </div>
                </a>
              </div>
              <div className='card-4'>
                <a href="https://financexcapital.com.br/project-finance/" target="_blank" rel="noopener noreferrer">
                  <h2>Project Finance</h2>
                  <div className='card_Text'>
                    <p>Estruturação de crédito personalizada para grandes projetos, utilizando Certificados de Recebíveis Agrícolas (CRA) e Imobiliários (CRI), permitindo acesso a grandes volumes de capital.</p>
                  </div>
                </a>
              </div>
              <div className='card-5'>
                <a href="https://financexcapital.com.br/pronampe/" target="_blank" rel="noopener noreferrer">
                  <h2>PRONAMPE</h2>
                  <div className='card_Text'>
                    <p>Programa de apoio às micro e pequenas empresas com condições diferenciadas e facilidades de pagamento.</p>
                  </div>
                </a>
              </div>
            </div>
            <h1 className='card_Title'>VANTAGENS E BENEFÍCIOS AO SEU ALCANCE</h1>
            <div className='card_Container'>
              <div className='container'>
                <div className='container_Image'>
                  <img src="/images/icons/icon-5.svg" alt="icon" />
                </div>
                <h3>Taxas Competitivas</h3>
                <p>Oferecemos as melhores condições do mercado, adaptadas às suas necessidades.</p>
              </div>
              <div className='container'>
                <div className='container_Image'>
                  <img src="/images/icons/icon-1.svg" alt="icon" />
                </div>
                <h3>Flexibilidade e Personalização </h3>
                <p>Soluções financeiras moldadas para seu perfil e objetivos, sejam eles pessoais ou empresariais.</p>
              </div>
              <div className='container'>
                <div className='container_Image'>
                  <img src="/images/icons/icon-2.svg" alt="icon" />
                </div>
                <h3>Acesso Facilitado</h3>
                <p>Procedimentos ágeis e descomplicados, garantindo acesso rápido ao crédito.</p>
              </div>
            </div>
            <div className='card_Container'>
              <div className='container'>
                <div className='container_Image'>
                  <img src="/images/icons/icon-3.svg" alt="icon" />
                </div>
                <h3>Suporte ao Crescimento</h3>
                <p>Recursos direcionados para investimentos estratégicos em sua empresa.</p>
              </div>
              <div className='container'>
                <div className='container_Image'>
                  <img src="/images/icons/icon-4.svg" alt="icon" />
                </div>
                <h3>Segurança</h3>
                <p>Crédito respaldado por garantias sólidas, proporcionando maior tranquilidade.</p>
              </div>
            </div>
            <button onClick={handleClick}>Fale com nossos especialistas</button>
        </div>
    </div>
  )
}

export default Cards
