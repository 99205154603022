import React from 'react'
import "../components/Services.css"
import { Link } from 'react-router-dom'; 

function Services() {
  return (
    <div className='services'>
      <div className='services_Content'>
        <Link to="/linhas-de-credito" className='services_Card'>
          <div className='services_Header'>
          <svg width="20px" height="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#00ED4A" d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8 316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496z"/></svg>
          <h1>LINHAS DE CRÉDITO</h1>
          </div>
          <p>As liberações podem ser realizadas da seguinte forma:
                <li>Bem em Garantia (Home Equity)</li>
                <li>Crédito no Aval</li>
                <li>BNDES</li>
                <li>PRONAMPE</li>
                <li>Project Finance (CRA, CRI, Crédito Estruturado)</li>
            </p>
        </Link>
        <Link to="/antecipacao-de-credito"className='services_Card'>
          <div className='services_Header'>
          <svg width="20px" height="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#00ED4A" d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8 316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496z"/></svg>
          <h1>ANTECIPAÇÃO DE RECEBÍVEIS</h1>
          </div>
          <p>Utilizando o acesso à fundos parceiros exclusivos, conseguimos realizar a antecipação de recebíveis da sua empresa.<br></br><br></br>
            Entre em contato com um de nossos assessores, para conhecer mais. 
            </p>
        </Link>
        <Link to="/consorcio"className='services_Card'>
          <div className='services_Header'>
          <svg width="20px" height="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#00ED4A" d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8 316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496z"/></svg>
          <h1>CONSÓRCIO</h1>
          </div>
          <p>A Finance X Capital é vinculada ao grupo
            operacional Santander, e a Caixa Econômica Federal de liberações de crédito via consórcio;
            <br></br><br></br>
            Operamos através de Cartas de Consórcio.
            </p>
        </Link>
        <div className='services_Card'>
          <div className='services_Header'>
          <svg width="20px" height="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#00ED4A" d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8 316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496z"/></svg>
          <h1>OUTROS SERVIÇOS</h1>
          </div>
          <p>Também realizamos:
                <li>Mesa dedicada de renda variável</li>
                <li>Câmbio</li>
                <li>Recuperação Tributária</li>
                <li>Redução Tributária</li>
                <li>Precatório</li>
            </p>
        </div>
      </div>
      </div>
  )
}

export default Services