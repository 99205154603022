import React from 'react'
import "../components/AboutBanner.css"

function AboutBanner() {
  return (
    <div className='about_Banner'>
      <h1>Entre em contato conosco</h1>
      <p>Nos deixe saber como podemos te ajudar.</p>
    </div>
  )
}

export default AboutBanner
